import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Form, Input, Select } from 'antd';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { textSort } from '../../helpers/textSort';
import { EditableContext } from './ModelPortfolioConstraints';

const ModelEditableCellConstraints = ({
  title,
  editing,
  children,
  dataIndex,
  record,
  handleSave,
  assetClasses,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  useEffect(() => {
    if (dataIndex) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [dataIndex]);

  const save = async () => {
    try {
      const values = await form.validateFields();
      console.log('🚀 ~ save ~ values:', values);
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  if (editing) {
    return (
      <td {...restProps}>
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          {dataIndex === 'name'
            ? (
            <Input
              className='custom-input'
              style={{ width: '100%' }}
              onChange={save}
              placeholder={record[title]}
            />
              )
            : dataIndex === 'asset_classes'
              ? (
              <CustomSelect
                width="100%"
                mode={'multiple'}
                maxTagCount='responsive'
                maxTagTextLength={12}
                className="custom-select label"
                onSelect={save}
                onDeselect={save}
                placeholder={record[title]}
              >
              {assetClasses
                ?.slice()
                ?.sort((a, b) => textSort(a.name, b.name))
                ?.map(({ ID, name }) => (
                <Select.Option key={ID} value={ID}>
                  {name}
                </Select.Option>
                ))
              }
              </CustomSelect>
                )
              : (
              <td {...restProps}>
                {children}
              </td>
                )
            }
        </Form.Item>
      </td>
    );
  } else {
    return (
      <td {...restProps}>
        {children}
      </td>);
  }
};

export default ModelEditableCellConstraints;

ModelEditableCellConstraints.propTypes = {
  title: PropTypes.node,
  editing: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
  assetClasses: PropTypes.array,
};
