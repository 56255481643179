import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, Row } from 'antd';
import { deleteNotActiveModel } from '../../data/store/instruments/instrumentsActions';

const ModalDeleteModel = ({modalDeleteModel, handleDeleteClose}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const { modelList } = useSelector((state) => state.instrumentsReducer);
  const [errorMessage, setErrorMessage ] = useState(false);

  const getModelById = () => {
    return modelList?.find(i => i.id === modalDeleteModel)?.name;
  };

  const handleErrorMessage = () => {
    setErrorMessage('Error! Cannot delete model');
    setTimeout(() => {
      setErrorMessage(false);
    }, 2500);
  };

  const handleDelete = () => {
    dispatch(deleteNotActiveModel({id: modalDeleteModel, callback: handleDeleteClose, errMessage: handleErrorMessage}));
  };

  return (
    <Modal
        width={650}
        className="modal-content-paper"
        open={modalDeleteModel}
        onCancel={handleDeleteClose}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_MODAL')}</p>
            <p className="modal-advisor-name">{getModelById()}</p>
          </Col>
        </Row>
        {errorMessage && <Row className='error-del-modal'>{errorMessage}</Row>}
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={handleDeleteClose}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={handleDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModalDeleteModel;

ModalDeleteModel.propTypes = {
  modalDeleteModel: PropTypes.number,
  handleDeleteClose: PropTypes.func,
};
