import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Col, Modal, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteModelLabel } from '../../data/store/instruments/instrumentsActions';

const ModelDeleteModalLabel = ({openModalDelete, closeOpenModalDelete, labels}) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const getLabel = (id) => labels?.find(i => i.id === id);

  const onSubmitDelete = () => {
    dispatch(deleteModelLabel({id: getLabel(openModalDelete)?.asset_class_model_id, labelId: openModalDelete }));
    closeOpenModalDelete();
  };

  return (
    <Modal
        width={650}
        className="modal-content-paper"
        open={openModalDelete}
        onCancel={closeOpenModalDelete}
        footer={null}
        zIndex={1600}>
        <Row justify="center">
          <Col className="modal-title">{t('MODAL_DELETE_TITLE')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">{t('MODAL_DELETE_LABEL')}</p>
            <p className="modal-advisor-name">{getLabel(openModalDelete)?.name}</p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={closeOpenModalDelete}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn delete-btn" onClick={onSubmitDelete}>
            {t('DELETE')}
          </Button>
        </Row>
      </Modal>
  );
};

export default ModelDeleteModalLabel;

ModelDeleteModalLabel.propTypes = {
  openModalDelete: PropTypes.number,
  closeOpenModalDelete: PropTypes.func,
  labels: PropTypes.array,
};