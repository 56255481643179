import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { Button, Col, DatePicker, Form, Layout, Row } from 'antd';
import { ReactComponent as Picker } from '../../assets/date-picker-icon.svg';
import Container from '../../components/Container';
import dayjs from 'dayjs';
import { steps } from './ModelItems';

const { Header } = Layout;
const ModelStepSettings = ({modelSeries, current, setNewDateRange}) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if(modelSeries?.model_start_date && modelSeries?.model_end_date) {
      const date = {
        model_start_date: dayjs(modelSeries.model_start_date),
        model_end_date: dayjs(modelSeries.model_end_date)
      };
      form.setFieldsValue(date);
    }
  }, [modelSeries]);
  
  
  const getPoint = (key) => {
    return steps.find(i => i.key === key)?.value;
  };

  
  const handleCancel = () => {
    history.replace('/quant/model/list');
  };

  const handleContinue = () => {
    history.push(`${pathname}?step=${getPoint(current + 1)}`);
  };

  const onSubmit = (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);
    const date = {
      model_start_date: dayjs(data.model_start_date).format('YYYY-MM'),
      model_end_date: dayjs(data.model_end_date).format('YYYY-MM'),
    };
    setNewDateRange(date);
    handleContinue();
  };

  return (
    <>
    <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t('NEW_MODEL')}
            </Button>
          </Col>
        </Row>
        <div className="top-btn-wrapper">
          <Button
            className="portfolio-details-btn portfolio-export-btn"
            onClick={handleCancel}>
            {t('CANCEL')}
          </Button>
          <Button
            onClick={() => form.submit()}
            className="portfolio-details-btn btn-edit-save">
            {t('CONTINUE')}
          </Button>
        </div>
      </Header>
      <h2 style={{margin: '24px 0'}} className="out-container-title">{t('SETTINGS')}</h2>
      <div style={{ padding: '0 24px' }}>
        <Container widthP={100} mb="24px" padding="48px 24px">
          <Form
            form={form}
            name="onboarding-form"
            layout="vertical"
            onFinish={onSubmit}
            className="instrument-form"
            autoComplete="off"
          >
          <Row justify={'start'} style={{gap: '24px'}}>
            <Col>
              <Form.Item
                label={t('START')}
                className="custom-required"
                name={'model_start_date'}
                rules={[{ required: true, message: t('ENTER_DATA') }]}
                required={false}
              >
                <DatePicker
                  picker='month'
                  placeholder="yyyy-mm"
                  format={'YYYY-MM'}
                  className="finplan-datepicker onboarding-datepicker"
                  suffixIcon={<Picker />}
                  onChange={(value, dateString) => {
                    if (value) {
                      console.log('Selected Date:', dateString);
                      form.setFieldValue('model_start', value );
                    }
                  }}
                />
              </Form.Item>

            </Col>
            <Col>
              <Form.Item
                label={t('END')}
                className="custom-required"
                name={'model_end_date'}
                rules={[{ required: true, message: t('ENTER_DATA') }]}
                required={false}
              >
                <DatePicker
                  picker='month'
                  placeholder="yyyy-mm"
                  format={'YYYY-MM'}
                  className="finplan-datepicker onboarding-datepicker"
                  suffixIcon={<Picker />}
                  onChange={(value, dateString) => {
                    if (value) {
                      console.log('Selected Date:', dateString);
                      form.setFieldValue('model_end', value);
                    }
                  }}
                />
              </Form.Item>

            </Col>
          </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default ModelStepSettings;

ModelStepSettings.propTypes = {
  model: PropTypes.object,
  modelSeries: PropTypes.object,
  current: PropTypes.number,
  newDateRange: PropTypes.object,
  setNewDateRange: PropTypes.func,
  
};
