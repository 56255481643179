import React from 'react';

import PropTypes from 'prop-types';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AdminInstruments from '../AdminInstruments/AdminInstruments';
import NewInstrument from '../AdminInstruments/NewInstrument';
import AssetClassManagement from '../AssetClassManagement/AssetClassManagement';
import ModelRouting from '../Model/ModelRouting';

const QuantRouting = ({
  needSave,
  setNeedSave,
  saveAlert,
  setSaveAlert,
  optAlert,
  setOptAlert,
  setOptStart,
}) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
        <Route path={`${url}/model`}>
            <ModelRouting />
        </Route>
        <Route path={`${url}/portfolio-constraints`}>
          <div
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1>Portfolio Constraints</h1>
          </div>
        </Route>
        <Route path={`${url}/approved-instruments`}>
          <div
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1>Approved Instruments</h1>
          </div>
        </Route>
        <Route path={`${url}/investor-bias`}>
          <div
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1>Investor Bias</h1>
          </div>
        </Route>
        <Route exact path={`${url}/asset/new-instrument`}>
          <NewInstrument
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
        <Route exact path={`${url}/asset/edit-instrument/:instrId`}>
          <NewInstrument
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
        <Route path={`${url}/asset/:assetTab`}>
            <AdminInstruments
              needSave={needSave}
              setNeedSave={setNeedSave}
              saveAlert={saveAlert}
              setSaveAlert={setSaveAlert}
              optAlert={optAlert}
              setOptAlert={setOptAlert}
              setOptStart={setOptStart}
            />
        </Route>
        <Route path={`${url}/asset-classes`}>
            <AssetClassManagement
              needSave={needSave}
              setNeedSave={setNeedSave}
              saveAlert={saveAlert}
              setSaveAlert={setSaveAlert}
              optAlert={optAlert}
              setOptAlert={setOptAlert}
              setOptStart={setOptStart}
            />
        </Route>
    </Switch>
  );
};

export default QuantRouting;

QuantRouting.propTypes = {
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  optAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};
