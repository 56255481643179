import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Form, Input, Modal, Row, Select } from 'antd';
import { CustomTextarea } from '../../components/CustomTextfield/CustomTextfield';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { createModel } from '../../data/store/instruments/instrumentsActions';

const ModelModalNew = ({modelList, modalOpen, handleClose}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isClone, setIsClone] = useState(false);

  const handleToggleClone = () => {
    if(isClone) form.setFieldValue('parent', undefined);
    setIsClone(!isClone);
  };

  const closeModal = () => {
    form.resetFields();
    setIsClone(false);
    handleClose();
  };

  const handleSubmit = (data) => {
    if(!data?.parent) delete data.parent;
    console.log('submit', data);
    dispatch(createModel({history, callback: closeModal, data}));

  };

  return (
    <Modal
      destroyOnClose
      width={650}
      centered
      open={modalOpen}
      onCancel={closeModal}
      footer={false}
      >
      <h1 className='model-modal-title'>{t('ADD_MODEL')}</h1>
      <Form
        form={form}
        name="new-model-form"
        layout="vertical"
        onFinish={handleSubmit}
        className="instrument-form"
        autoComplete="off"
      >
      <h2 className='model-modal-subtitle'>{t('PROVIDE_MODEL_NAME')}</h2>
      <Form.Item
        className='custom-required'
        label={t('NAME')}
        name="name"
        rules={[
          {
            required: true,
            message: t('PLEASE_ENTER_A_NAME'),
          },
        ]}
        required={false}>
        <Input
          placeholder={t('NAME')}
          className='custom-input'/>
      </Form.Item>
      <Form.Item
        name='description'
        className="onboarding-textarea-purpose custom-required"
        rules={[{ required: true, message: t('ENTER_DESCRIPTION') }]}
        required={false}
        label={t('DESCRIPTION')}>
        <CustomTextarea rows={4} placeholder={t('ENTER_DESCRIPTION')} />
      </Form.Item>
      <div className='clone-checkbox-wrapper'>
        <CustomCheckbox 
          checked={isClone}
          onChange={handleToggleClone}
        >{t('CLONE_MODEL')}</CustomCheckbox>
      </div>
      <Form.Item
        name="parent"
        rules={[{ required: isClone, message: t('ENTER_DESCRIPTION') }]}
        required={false}
      >
        <CustomSelect
          disabled={!isClone}
          placeholder={t('SELECT_AN_OPTION')}
          className="custom-select"
          >
          {modelList?.map(i => (
            <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>
          ))}
        </CustomSelect>
      </Form.Item>
      <Row>
        <Button
          key="ok"
          className="modal-action-btn save-btn model-modal-btn"
          onClick={() => form.submit()}
        >
          {t('SUBMIT')}
        </Button>
      </Row>
      </Form>
      </Modal>
  );
};

export default ModelModalNew;

ModelModalNew.propTypes = {
  modelList: PropTypes.array,
  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};