/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Button, Col, Form, Layout, Row } from 'antd';

import { ReactComponent as Upload } from '../../assets/upoad-icon.svg';
import Container from '../../components/Container';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { SortArrow } from '../Clients/ClientSummary';
import { mockPrimitives } from '../../mocks/mockPrimitives';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import { clearPrimitives, getModelPrimitives, setModelPrimitives } from '../../data/store/instruments/instrumentsActions';
import cloneDeep from 'lodash/cloneDeep';
import { steps } from './ModelItems';

const { Header } = Layout;
const ModelPrimitives = ({model, current}) => {
  const { t } = useTranslation('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  
  const inputXlsx = useRef(null);
  const assets = useSelector((state) => state.instrumentsReducer.assets);
  const primitives = useSelector((state) => state.instrumentsReducer.primitives);

  const [storedPrimitives, setStoredPrimitives] = useState(null);

  const getPoint = (key) => {
    return steps.find((i) => i.key === key)?.value;
  };

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ storedPrimitives:', storedPrimitives);
  }, [storedPrimitives]);
  

  useEffect(() => {
  
    return () => {
      dispatch(clearPrimitives());
    };
  }, []);

  useEffect(() => {
    if(primitives) {
      setStoredPrimitives(primitives);
    } else if(!primitives && model?.id){
      dispatch(getModelPrimitives(model.id));
    }
  
  }, [primitives, model]);
  

  const getAssetById = (id) => {
    const asset = assets.find(asset => asset.ID === id);
    return {name: asset?.name ?? '', category: asset?.financialAssetCategory.name ?? ''};
  };

  const [sortValue, setSortValue] = useState(null);
  const [sortClass, setSortClass] = useState(null);
  const [sortAllocation, setSortAllocation] = useState(null);
  
  const meanReturnOptions = [
    {value: 1,
      label: t('MEAN_LINEAR_RETURN')},
    {value: 2,
      label: t('MEAN_LOG_RETURN')},
    {value: 3,
      label: t('VARIANCE')},
    {value: 4,
      label: t('STANDARD_DEV_VOLATILITY')},
    {value: 61,
      label: t('WEIGHT_RANGE_VOLATILITY_ADJUSTMENT')},
  ];
  const [fileUploaded, setFileUploaded] = useState(null);
  const [primitivesSet, setPrimitivesSet] = useState(null);
  const primitivesType = Form.useWatch('meanReturn', form);
  const [lockedSet, setLockedSet] = useState({'1': false, '2': false, '3': false, '4': false, '61': false});

  useEffect(() => {
    console.log('lockedSet', lockedSet);
  }, [lockedSet]);
  

  useEffect(() => {
    if(primitivesType && storedPrimitives) {
      const data = storedPrimitives?.find( i=> i.type === +primitivesType);
      const isBlanck = data?.mean_return?.every(i => i.adjustment === null);
      const newLockedSet = {...lockedSet};

      if(primitivesType === 1 && isBlanck) {
        newLockedSet['2'] = false;
      } else if(primitivesType === 1){
        newLockedSet['2'] = true;
      }
      if(primitivesType === 2 && isBlanck) {
        newLockedSet['1'] = false;
      } else if(primitivesType === 2){
        newLockedSet['1'] = true;
      }
      
      if(primitivesType === 3 && isBlanck) {
        newLockedSet['4'] = false;
      } else if(primitivesType === 3){
        newLockedSet['4'] = true;
      }
      if(primitivesType === 4 && isBlanck) {
        newLockedSet['3'] = false;
      } else if(primitivesType === 4){
        newLockedSet['3'] = true;
      }
      
      setLockedSet(newLockedSet);
      setPrimitivesSet(data);
    }
  }, [primitivesType, storedPrimitives]);
  
  useEffect(() => {
    form.setFieldsValue({meanReturn: 1});
  }, []);

  const hendleChangeValue = (primitivesType, asset_class_id, value, cell) => {
    const clonData = cloneDeep(storedPrimitives);
    const newData = clonData.map(i => {
      if(i.type === primitivesType) {
        const el = {...i, mean_return: i.mean_return
          .map(item => item.asset_class_id === asset_class_id 
            ? {...item, adjustment: primitivesType !== 61 ?  +value : {...item.adjustment, [cell]: +value}}
            : item)};
            return el;
        } else {
            return i;
        }
    });
    setStoredPrimitives(newData);
  };

  const handleSort = (coll) => {
    if (coll === 'value') {
      setSortClass(null);
      setSortAllocation(null);
      if (sortValue !== null) {
        if (sortValue) {
          setSortValue(false);
        } else {
          setSortValue(null);
        }
      } else {
        setSortValue(true);
      }
    } else if (coll === 'class') {
      setSortValue(null);
      setSortAllocation(null);
      if (sortClass !== null) {
        if (!sortClass) {
          setSortClass(true);
        } else {
          setSortClass(null);
        }
      } else {
        setSortClass(false);
      }
    } else if (coll === 'allocation') {
      setSortValue(null);
      setSortClass(null);
      if (sortAllocation !== null) {
        if (!sortAllocation) {
          setSortAllocation(true);
        } else {
          setSortAllocation(null);
        }
      } else {
        setSortAllocation(false);
      }
    }
  };
  

  useEffect(() => {}, [fileUploaded]);

  const uploadPortfolio = (event) => {
    const file = event.target.files[0];
    setFileUploaded(file);
  };

  const handleUpload = () => {
    if (inputXlsx.current) {
      inputXlsx.current.click();
    }
  };

  const handleCancel = () => {
    history.replace(`${pathname}?step=index-series`);
  };

  const handleClearAll = () => {
    const clonData = cloneDeep(storedPrimitives);
    const newData = clonData.map(i => {
      if(i.type === primitivesType) {
        const el = {...i, mean_return: i.mean_return
          .map(item => ({...item, adjustment: null}))};
            return el;
        } else {
            return i;
        }
    });
    setStoredPrimitives(newData);
  };
  const handleContinue = () => {
    history.push(`${pathname}?step=${getPoint(current + 1)}`);
  };

  const onSubmit = (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);
    dispatch(setModelPrimitives({callback: handleContinue, id: model?.id, data: storedPrimitives }));
  };

  return (
    <>
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t('NEW_MODEL')}
            </Button>
          </Col>
        </Row>
        <div className="top-btn-wrapper">
          <Button
            className="portfolio-details-btn portfolio-export-btn"
            onClick={handleCancel}
          >
            {t('BACK_TO_INDEX_SERIES')}
          </Button>
          <Button
            onClick={() => form.submit()}
            className="portfolio-details-btn btn-edit-save"
          >
            {t('CONTINUE')}
          </Button>
        </div>
      </Header>
      <Row justify={'space-between'}>
        <Col>
          <h2 style={{margin: '24px 0'}} className="out-container-title">{t('MODEL_PRIMITIVES')}</h2>
        </Col>
        <Col className="titlerow-btn-wrapper">
          <Button
            type="text"
            className="portfolio-creat-download upload-model-btn"
            icon={<Upload />}
            iconPosition={'end'}
            onClick={handleUpload}
          >
            {t('UPLOAD_FROM_EXEL')}
          </Button>
          <input
            ref={inputXlsx}
            type="file"
            accept={'.xlsx'}
            className="portfolio-creat-download-input"
            onChange={uploadPortfolio}
          />
        </Col>
      </Row>
      <div style={{ padding: '0 24px' }}>
        <Container widthP={100} mb="24px" padding="0">
          <Form
            form={form}
            name="primitives-form"
            layout="vertical"
            onFinish={onSubmit}
            className="instrument-form"
            autoComplete="off"
          >
            <Row justify={'start'} style={{ padding: '24px 24px 0' }}>
              <Form.Item name={'meanReturn'}>
                <CustomSelect
                  placeholder={t('SELECT_ASSET_CLASS')}
                  className="custom-select asset-select-models"
                  options={meanReturnOptions}
                />
              </Form.Item>
            </Row>
            <Row wrap={false} className="primitives-header">
              <Col span={primitivesType === 61 ? 6 : 8} className="primitives-title-class">
                {t('ASSET_CLASS')}
                <div onClick={() => handleSort('class')}>
                  <SortArrow
                    direction={
                      sortClass ? 'up' : sortClass !== null ? 'down' : ''
                    }
                  />
                </div>
              </Col>
              {primitivesType === 61
              ? (
                <>
                  <Col span={2} className="primitives-title-value">
                    {t('p1')}
                    <div onClick={() => handleSort('value')}>
                      <SortArrow
                        direction={
                          sortValue ? 'up' : sortValue !== null ? 'down' : ''
                        }
                      />
                    </div>
                  </Col>
                  <Col span={3} className="primitives-title-override">
                    {t('OVERRIDE') + ' p1'}
                  </Col>
                  <Col span={2} className="primitives-title-value">
                    {t('p2')}
                    <div onClick={() => handleSort('value')}>
                      <SortArrow
                        direction={
                          sortValue ? 'up' : sortValue !== null ? 'down' : ''
                        }
                      />
                    </div>
                  </Col>
                  <Col span={3} className="primitives-title-override">
                    {t('OVERRIDE') + ' p2'}
                  </Col>
                  <Col span={2} className="primitives-title-value">
                    {t('VALUE')}
                    <div onClick={() => handleSort('value')}>
                      <SortArrow
                        direction={
                          sortValue ? 'up' : sortValue !== null ? 'down' : ''
                        }
                      />
                    </div>
                  </Col>
                  <Col span={3} className="primitives-title-override">
                    {t('OVERRIDE')}
                  </Col>

                </>
              )
              : <>
                  <Col span={8} className="primitives-title-value">
                    {t('QUANT_VALUE')}
                    <div onClick={() => handleSort('value')}>
                      <SortArrow
                        direction={
                          sortValue ? 'up' : sortValue !== null ? 'down' : ''
                        }
                      />
                    </div>
                  </Col>
                  <Col span={4} className="primitives-title-override">
                    {t('OVERRIDE')}
                  </Col>
              </>
              }
              <Col span={primitivesType === 61 ? 3 : 4} className="asset-class-title-allocation">
              <Button
                className="portfolio-details-btn portfolio-export-btn"
                onClick={handleClearAll}
              >
                {t('CLEAR_ALL')}
              </Button>
              </Col>
            </Row>
            {primitivesSet &&
              primitivesSet?.mean_return?.map((el, index) => {
                return (
                  <Row key={el.asset_class_id} wrap={false} className={`primitives-row ${index % 2 === 0 ? '' : 'is-odd'}`}>
                    <Col span={primitivesType === 61 ? 6 : 8} className="primitives-title-class">
                      <div className=''>
                        <p className='primitives-cell-name'>{getAssetById(el.asset_class_id)?.name}</p>
                        <p className='primitives-cell-category'>{getAssetById(el.asset_class_id)?.category}</p>
                      </div>
                    </Col>
                    {typeof el?.adjustment === 'object' && primitivesType === 61
                    ? (
                      <>
                      <Col span={2} style={{marginLeft: '-12px'}} className="primitives-title-value">
                        <div className=''>{el?.quant_value?.p1}</div>
                      </Col>
                      <Col span={3} className="primitives-title-override">
                        <div className=''>
                          <CustomInputNumber
                            width={'132px'}
                            value={el?.adjustment?.p1}
                            percent
                            precision={2}
                            min={0}
                            onChange={(value) =>
                              hendleChangeValue(primitivesType, el.asset_class_id, value, 'p1')
                            }
                            className="custom-inputnumber model-series"
                            controls={false}
                          />
                        </div>
                      </Col>
                      <Col span={2} style={{marginLeft: '-6px'}} className="primitives-title-value">
                        <div className=''>{el?.quant_value?.p2}</div>
                      </Col>
                      <Col span={3} className="primitives-title-override">
                        <div className=''>
                          <CustomInputNumber
                            width={'132px'}
                            value={el?.adjustment?.p2}
                            percent
                            precision={2}
                            min={0}
                            onChange={(value) =>
                              hendleChangeValue(primitivesType, el.asset_class_id, value, 'p2')
                            }
                            className="custom-inputnumber model-series"
                            controls={false}
                          />
                        </div>
                      </Col>
                      <Col span={2} style={{marginLeft: '-8px'}} className="primitives-title-value">
                        <div className=''>{el?.quant_value?.value}</div>
                      </Col>
                      <Col span={3} className="primitives-title-override">
                        <div className=''>
                          <CustomInputNumber
                            width={'132px'}
                            value={el?.adjustment?.value}
                            percent
                            precision={2}
                            min={0}
                            onChange={(value) =>
                              hendleChangeValue(primitivesType, el.asset_class_id, value, 'value')
                            }
                            className="custom-inputnumber model-series"
                            controls={false}
                          />
                        </div>
                      </Col>

                      </>
                    )
                    : (typeof el?.adjustment !== 'object' || el?.adjustment === null)
                      ? (
                        <>
                          <Col span={8} className="primitives-title-value cell">
                            <div className=''>{el?.quant_value}</div>
                          </Col>
                          <Col span={4} className="primitives-title-override cell">
                            <div className=''>
                              <CustomInputNumber
                                disabled={lockedSet[primitivesType]}
                                width={'132px'}
                                percent
                                precision={2}
                                min={0}
                                value={el?.adjustment}
                                onChange={(value) =>
                                  hendleChangeValue(primitivesType, el.asset_class_id, value)
                                }
                                className="custom-inputnumber model-series"
                                controls={false}
                              />
                            </div>
                          </Col>
                        </>
                      )
                      : null
                    }
                    <Col span={4} className="asset-class-title-allocation">
                    
                    </Col>
                  </Row>
                );
              })

            }
          </Form>
        </Container>
      </div>
    </>
  );
};

export default ModelPrimitives;

ModelPrimitives.propTypes = {
  model: PropTypes.object,
  current: PropTypes.number,
};