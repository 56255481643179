export const mockPrimitives = [
    {
        'type': 1,
        'mean_return': [
            {
                'asset_class_id': 27,
                'quant_value': 6.48,
                'adjustment': null
            },
            {
                'asset_class_id': 1,
                'quant_value': 9.05,
                'adjustment': null
            },
            {
                'asset_class_id': 3,
                'quant_value': 0.87,
                'adjustment': null
            },
            {
                'asset_class_id': 4,
                'quant_value': 9.43,
                'adjustment': null
            },
            {
                'asset_class_id': 5,
                'quant_value': 3.95,
                'adjustment': null
            },
            {
                'asset_class_id': 6,
                'quant_value': 6.98,
                'adjustment': null
            },
            {
                'asset_class_id': 7,
                'quant_value': 3.99,
                'adjustment': null
            },
            {
                'asset_class_id': 8,
                'quant_value': 3.71,
                'adjustment': null
            },
            {
                'asset_class_id': 2,
                'quant_value': 9.36,
                'adjustment': null
            },
            {
                'asset_class_id': 11,
                'quant_value': 5.21,
                'adjustment': null
            },
            {
                'asset_class_id': 12,
                'quant_value': 8.82,
                'adjustment': null
            },
            {
                'asset_class_id': 13,
                'quant_value': 3.81,
                'adjustment': null
            },
            {
                'asset_class_id': 14,
                'quant_value': 4.49,
                'adjustment': null
            },
            {
                'asset_class_id': 15,
                'quant_value': 8.32,
                'adjustment': null
            },
            {
                'asset_class_id': 16,
                'quant_value': 9.03,
                'adjustment': null
            },
            {
                'asset_class_id': 22,
                'quant_value': 0.11,
                'adjustment': null
            },
            {
                'asset_class_id': 9,
                'quant_value': 2.7,
                'adjustment': null
            },
            {
                'asset_class_id': 10,
                'quant_value': 6.52,
                'adjustment': null
            },
            {
                'asset_class_id': 31,
                'quant_value': 9.67,
                'adjustment': null
            },
            {
                'asset_class_id': 17,
                'quant_value': 9.21,
                'adjustment': null
            },
            {
                'asset_class_id': 18,
                'quant_value': 7.51,
                'adjustment': null
            },
            {
                'asset_class_id': 19,
                'quant_value': 9.66,
                'adjustment': null
            },
            {
                'asset_class_id': 20,
                'quant_value': 5.4,
                'adjustment': null
            },
            {
                'asset_class_id': 21,
                'quant_value': 8.1,
                'adjustment': null
            },
            {
                'asset_class_id': 32,
                'quant_value': 5.11,
                'adjustment': null
            }
        ]
    },
    {
        'type': 2,
        'mean_return': [
            {
                'asset_class_id': 27,
                'quant_value': 6.61,
                'adjustment': null
            },
            {
                'asset_class_id': 1,
                'quant_value': 7.41,
                'adjustment': null
            },
            {
                'asset_class_id': 3,
                'quant_value': 2.04,
                'adjustment': null
            },
            {
                'asset_class_id': 4,
                'quant_value': 2.94,
                'adjustment': null
            },
            {
                'asset_class_id': 5,
                'quant_value': 6.58,
                'adjustment': null
            },
            {
                'asset_class_id': 6,
                'quant_value': 5.85,
                'adjustment': null
            },
            {
                'asset_class_id': 7,
                'quant_value': 9.52,
                'adjustment': null
            },
            {
                'asset_class_id': 8,
                'quant_value': 4.52,
                'adjustment': null
            },
            {
                'asset_class_id': 2,
                'quant_value': 2.07,
                'adjustment': null
            },
            {
                'asset_class_id': 11,
                'quant_value': 8.86,
                'adjustment': null
            },
            {
                'asset_class_id': 12,
                'quant_value': 9.67,
                'adjustment': null
            },
            {
                'asset_class_id': 13,
                'quant_value': 7.46,
                'adjustment': null
            },
            {
                'asset_class_id': 14,
                'quant_value': 8.88,
                'adjustment': null
            },
            {
                'asset_class_id': 15,
                'quant_value': 4.58,
                'adjustment': null
            },
            {
                'asset_class_id': 16,
                'quant_value': 2.64,
                'adjustment': null
            },
            {
                'asset_class_id': 22,
                'quant_value': 3.56,
                'adjustment': null
            },
            {
                'asset_class_id': 9,
                'quant_value': 0.21,
                'adjustment': null
            },
            {
                'asset_class_id': 10,
                'quant_value': 6.39,
                'adjustment': null
            },
            {
                'asset_class_id': 31,
                'quant_value': 8.26,
                'adjustment': null
            },
            {
                'asset_class_id': 17,
                'quant_value': 5.07,
                'adjustment': null
            },
            {
                'asset_class_id': 18,
                'quant_value': 5.4,
                'adjustment': null
            },
            {
                'asset_class_id': 19,
                'quant_value': 1.44,
                'adjustment': null
            },
            {
                'asset_class_id': 20,
                'quant_value': 9,
                'adjustment': null
            },
            {
                'asset_class_id': 21,
                'quant_value': 1.28,
                'adjustment': null
            },
            {
                'asset_class_id': 32,
                'quant_value': 1.29,
                'adjustment': null
            }
        ]
    },
    {
        'type': 3,
        'mean_return': [
            {
                'asset_class_id': 27,
                'quant_value': 2.78,
                'adjustment': null
            },
            {
                'asset_class_id': 1,
                'quant_value': 2.41,
                'adjustment': null
            },
            {
                'asset_class_id': 3,
                'quant_value': 3.18,
                'adjustment': null
            },
            {
                'asset_class_id': 4,
                'quant_value': 8.09,
                'adjustment': null
            },
            {
                'asset_class_id': 5,
                'quant_value': 5.67,
                'adjustment': null
            },
            {
                'asset_class_id': 6,
                'quant_value': 6.57,
                'adjustment': null
            },
            {
                'asset_class_id': 7,
                'quant_value': 0.66,
                'adjustment': null
            },
            {
                'asset_class_id': 8,
                'quant_value': 2.81,
                'adjustment': null
            },
            {
                'asset_class_id': 2,
                'quant_value': 1.73,
                'adjustment': null
            },
            {
                'asset_class_id': 11,
                'quant_value': 2.29,
                'adjustment': null
            },
            {
                'asset_class_id': 12,
                'quant_value': 4.48,
                'adjustment': null
            },
            {
                'asset_class_id': 13,
                'quant_value': 5.16,
                'adjustment': null
            },
            {
                'asset_class_id': 14,
                'quant_value': 9.75,
                'adjustment': null
            },
            {
                'asset_class_id': 15,
                'quant_value': 7.56,
                'adjustment': null
            },
            {
                'asset_class_id': 16,
                'quant_value': 6.26,
                'adjustment': null
            },
            {
                'asset_class_id': 22,
                'quant_value': 2.85,
                'adjustment': null
            },
            {
                'asset_class_id': 9,
                'quant_value': 8.32,
                'adjustment': null
            },
            {
                'asset_class_id': 10,
                'quant_value': 9.91,
                'adjustment': null
            },
            {
                'asset_class_id': 31,
                'quant_value': 6.94,
                'adjustment': null
            },
            {
                'asset_class_id': 17,
                'quant_value': 0.34,
                'adjustment': null
            },
            {
                'asset_class_id': 18,
                'quant_value': 8.44,
                'adjustment': null
            },
            {
                'asset_class_id': 19,
                'quant_value': 1.61,
                'adjustment': null
            },
            {
                'asset_class_id': 20,
                'quant_value': 0.3,
                'adjustment': null
            },
            {
                'asset_class_id': 21,
                'quant_value': 2.86,
                'adjustment': null
            },
            {
                'asset_class_id': 32,
                'quant_value': 8.11,
                'adjustment': null
            }
        ]
    },
    {
        'type': 4,
        'mean_return': [
            {
                'asset_class_id': 27,
                'quant_value': 8.39,
                'adjustment': null
            },
            {
                'asset_class_id': 1,
                'quant_value': 8.3,
                'adjustment': null
            },
            {
                'asset_class_id': 3,
                'quant_value': 9.07,
                'adjustment': null
            },
            {
                'asset_class_id': 4,
                'quant_value': 7.58,
                'adjustment': null
            },
            {
                'asset_class_id': 5,
                'quant_value': 2.95,
                'adjustment': null
            },
            {
                'asset_class_id': 6,
                'quant_value': 8.08,
                'adjustment': null
            },
            {
                'asset_class_id': 7,
                'quant_value': 8.26,
                'adjustment': null
            },
            {
                'asset_class_id': 8,
                'quant_value': 0.35,
                'adjustment': null
            },
            {
                'asset_class_id': 2,
                'quant_value': 9.7,
                'adjustment': null
            },
            {
                'asset_class_id': 11,
                'quant_value': 9.71,
                'adjustment': null
            },
            {
                'asset_class_id': 12,
                'quant_value': 4.62,
                'adjustment': null
            },
            {
                'asset_class_id': 13,
                'quant_value': 1.92,
                'adjustment': null
            },
            {
                'asset_class_id': 14,
                'quant_value': 9.57,
                'adjustment': null
            },
            {
                'asset_class_id': 15,
                'quant_value': 9.63,
                'adjustment': null
            },
            {
                'asset_class_id': 16,
                'quant_value': 6.09,
                'adjustment': null
            },
            {
                'asset_class_id': 22,
                'quant_value': 2.6,
                'adjustment': null
            },
            {
                'asset_class_id': 9,
                'quant_value': 5.35,
                'adjustment': null
            },
            {
                'asset_class_id': 10,
                'quant_value': 4.27,
                'adjustment': null
            },
            {
                'asset_class_id': 31,
                'quant_value': 6.93,
                'adjustment': null
            },
            {
                'asset_class_id': 17,
                'quant_value': 0.25,
                'adjustment': null
            },
            {
                'asset_class_id': 18,
                'quant_value': 1.74,
                'adjustment': null
            },
            {
                'asset_class_id': 19,
                'quant_value': 0.74,
                'adjustment': null
            },
            {
                'asset_class_id': 20,
                'quant_value': 1.87,
                'adjustment': null
            },
            {
                'asset_class_id': 21,
                'quant_value': 5.62,
                'adjustment': null
            },
            {
                'asset_class_id': 32,
                'quant_value': 4.6,
                'adjustment': null
            }
        ]
    },
    {
        'type': 61,
        'mean_return': [
            {
                'asset_class_id': 27,
                'quant_value': {
                    'p1': 6.66,
                    'p2': 2.55,
                    'value': 2
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 1,
                'quant_value': {
                    'p1': 1.68,
                    'p2': 8.88,
                    'value': 4.45
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 3,
                'quant_value': {
                    'p1': 8.39,
                    'p2': 6.65,
                    'value': 9.39
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 4,
                'quant_value': {
                    'p1': 6.55,
                    'p2': 9.44,
                    'value': 8.91
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 5,
                'quant_value': {
                    'p1': 9.25,
                    'p2': 8.33,
                    'value': 2.64
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 6,
                'quant_value': {
                    'p1': 9.67,
                    'p2': 6.36,
                    'value': 8.12
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 7,
                'quant_value': {
                    'p1': 1.92,
                    'p2': 2.48,
                    'value': 2.87
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 8,
                'quant_value': {
                    'p1': 0.6,
                    'p2': 8.33,
                    'value': 1.3
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 2,
                'quant_value': {
                    'p1': 2.58,
                    'p2': 3.02,
                    'value': 6.45
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 11,
                'quant_value': {
                    'p1': 7.51,
                    'p2': 1.19,
                    'value': 7.15
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 12,
                'quant_value': {
                    'p1': 7.89,
                    'p2': 0.55,
                    'value': 7.98
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 13,
                'quant_value': {
                    'p1': 4.98,
                    'p2': 5.34,
                    'value': 3.51
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 14,
                'quant_value': {
                    'p1': 4.13,
                    'p2': 7.42,
                    'value': 6.93
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 15,
                'quant_value': {
                    'p1': 2.99,
                    'p2': 5.97,
                    'value': 2.58
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 16,
                'quant_value': {
                    'p1': 2.82,
                    'p2': 2.31,
                    'value': 8.82
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 22,
                'quant_value': {
                    'p1': 1.32,
                    'p2': 7.62,
                    'value': 7.06
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 9,
                'quant_value': {
                    'p1': 7.78,
                    'p2': 3.27,
                    'value': 7.79
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 10,
                'quant_value': {
                    'p1': 9.36,
                    'p2': 6.26,
                    'value': 0.2
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 31,
                'quant_value': {
                    'p1': 3.78,
                    'p2': 9.39,
                    'value': 0.11
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 17,
                'quant_value': {
                    'p1': 0.03,
                    'p2': 3.8,
                    'value': 1.41
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 18,
                'quant_value': {
                    'p1': 7.88,
                    'p2': 3.45,
                    'value': 9.59
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 19,
                'quant_value': {
                    'p1': 5.83,
                    'p2': 0.07,
                    'value': 5.6
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 20,
                'quant_value': {
                    'p1': 7.52,
                    'p2': 6.96,
                    'value': 9.17
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 21,
                'quant_value': {
                    'p1': 6.23,
                    'p2': 4.26,
                    'value': 6.03
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            },
            {
                'asset_class_id': 32,
                'quant_value': {
                    'p1': 4.88,
                    'p2': 6.02,
                    'value': 0.5
                },
                'adjustment': {
                    'p1': null,
                    'p2': null,
                    'value': null
                }
            }
        ]
    }
];