import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as Checked} from '../../assets/check-icon.svg';

import './style.scss';

const StepItem = ({t, item, current, checked}) => {
  return (
      <div className={`custom-steps-item-wrap ${current ? 'current' : ''} ${checked ? 'checked' : ''}`}>
        {t(item.name)}
        <div className='custom-steps-item-dot'>{checked ? <Checked/> : item.key}</div>
      </div>
  );
};
const CustomSteps = ({t, steps, current}) => {
  return (
    <div className='custom-steps-wrapper'>
      {
        steps?.map(i => (
          <React.Fragment key={i.key}>
            <StepItem
              t={t}
              item={i}
              current={i.key === current}
              checked={i.key < current}
            />
            {steps?.length - 1 >= i.key && <span className='custom-steps-didider'/>}
          </React.Fragment>
        ))
      }
    </div>
  );
};

export default CustomSteps;

CustomSteps.propTypes = {
  t: PropTypes.func,
  steps: PropTypes.array,
  current: PropTypes.number,
};

StepItem.propTypes = {
  t: PropTypes.func,
  item: PropTypes.object,
  current: PropTypes.bool,
  checked: PropTypes.bool,
};
