import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import MainFooter from '../../components/MainFooter';

import { useQuery } from '../../hooks/useQuery';
import ModelStepSettings from './ModelStepSettings';
import CustomSteps from '../../components/CustomSteps/CustomSteps';

import ModelStepIndexSeries from './ModelStepIndexSeries';

import { getModelById, getModelSeries } from '../../data/store/instruments/instrumentsActions';
import './style.scss';
import ModelPrimitives from './ModelPrimitives';
import ModelPortfolioConstraints from './ModelPortfolioConstraints';

export const steps = [
  {
    key: 1,
    name: 'SETTINGS',
    value: 'settings'
  },
  {
    key: 2,
    name: 'INDEX_SERIES',
    value: 'index-series'
  },
  {
    key: 3,
    name: 'MODEL_PRIMITIVES',
    value: 'model-primitives'
  },
  {
    key: 4,
    name: 'PORTFOLIO_CONSTRAINTS',
    value: 'portfolio-constraints'
  },
];

const ModelItems = () => {
  const { t } = useTranslation('');
  const { search, pathname } = useLocation();
  const history = useHistory();
  const query = useQuery(search);
  const dispatch = useDispatch();

  const model = useSelector(state => state.instrumentsReducer.modelById);
  const modelSeries = useSelector(state => state.instrumentsReducer.modelSeries);
  const [newDateRange, setNewDateRange] = useState(null);

  const step = query.get('step');

  const { itemKey } = useParams();

  useEffect(() => {
    console.log('newDateRange', newDateRange);
  }, [newDateRange]);
  

  useEffect(() => {
    if(modelSeries) {
      const date = {
        model_start_date: modelSeries.model_start_date,
        model_end_date: modelSeries.model_end_date
      };
      setNewDateRange(date);
    }
  }, [modelSeries]);

  useEffect(() => {
    if(model?.series_set_id) {
      dispatch(getModelSeries({id: itemKey, seriesSetId: model.series_set_id}));
    }
  }, [model]);
  

  useEffect(() => {
    if(itemKey) {
      dispatch(getModelById(itemKey));
    }
  }, [itemKey]);

  useEffect(() => {
    if(!step) {
      history.push(`${pathname}?step=settings`);
    }
  }, [step]);

  const getCurrent = useMemo(() => {
    return steps.find(i => i.value === step)?.key;
  }, [step]);

  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
    <div>
      <CustomSteps
        t={t}
        steps={steps}
        current={getCurrent}
      />

      {step === 'settings' &&
        <ModelStepSettings 
          model={model}
          modelSeries={modelSeries}
          current={getCurrent}
          newDateRange={newDateRange}
          setNewDateRange={setNewDateRange}
        />
      }
      {step === 'index-series' &&
        <ModelStepIndexSeries
          modelId={itemKey} 
          model={model}
          modelSeries={modelSeries}
          newDateRange={newDateRange}
          current={getCurrent}  
        />
      }
      {step === 'model-primitives' &&
        <ModelPrimitives 
          model={model}
          current={getCurrent}  
        />
      }
      {step === 'portfolio-constraints' &&
        <ModelPortfolioConstraints 
          model={model}
          current={getCurrent}  
        />
      }
    </div>
      <MainFooter />
    </div>
  );
};

export default ModelItems;
