export const generateDataObject = (modelStart, modelEnd) => {
    // Разделяем год и месяц для начальной и конечной дат
    const [startYear, startMonth] = modelStart.split('-').map(Number);
    const [endYear, endMonth] = modelEnd.split('-').map(Number);
  
    const result = [];
  
    for (let year = startYear; year <= endYear; year++) {
      const data = {};
  
      // Определяем стартовый и конечный месяцы для текущего года
      const start = year === startYear ? startMonth : 1;
      const end = year === endYear ? endMonth : 12;
  
      // Заполняем объект ключами month_XX
      for (let month = 1; month <= 12; month++) {
        const key = `month_${String(month).padStart(2, '0')}`;
        data[key] = month >= start && month <= end ? '' : null;
      }
  
      // Добавляем объект в результат
      result.push({ year, data });
    }
    console.log('🚀 ~ generateDataObject ~ result:', result);
  
    return result;
  };