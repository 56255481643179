/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// import dayjs from 'dayjs';
import {
  Button,
  Col,
  Layout,
  Progress,
  Row,
  Typography,
  Skeleton as SceletANTD,
  Modal,
  Tooltip,
} from 'antd';
import {
  clearCandidates,
  exportSelectedPortfolio,
  getAllPortfolio,
} from '../../data/store/portfolio/portfolioActions';
import {
  getCurrency,
  getInstrumentsAll,
} from '../../data/store/instruments/instrumentsActions';
import { ReactComponent as Pluse } from '../../assets/add-icon.svg';
// import { ReactComponent as Up } from '../../assets/arraw-green-step.svg';
// import { ReactComponent as Down } from '../../assets/arraw-red-step.svg';
import { ReactComponent as More } from '../../assets/more-icon.svg';
import { ReactComponent as Collapse } from '../../assets/collaps-icon.svg';
import { ReactComponent as Export } from '../../assets/export-icon.svg';

import PortfolioList from './PortfolioList';
import Container from '../../components/Container';
import {
  twoDecimalWithSpaces,
} from '../../helpers/formatFractional';
import { formatPercentage2 } from '../../helpers/formatPercentage2';
import { PieDonut } from '../../components/charts/PieDonut/PieDonut';
import { getPieData } from '../../hooks/getPieData';
import { getAsseData } from '../../hooks/getAsseData';

import MainFooter from '../../components/MainFooter';
import { Holdings } from './Holdings';
// import SkeletonClientInfo from './Skeleton/SkeletonClientInfo';
import Skeleton from 'react-loading-skeleton';

import './style.scss';
import { Cash } from './Cash';

const { Header } = Layout;
const { Title } = Typography;
// const { Paragraph } = Typography;

export const SortArrow = ({ direction }) => {
  return (
    <div className={'sortArroy'}>
      <svg
        viewBox="0 0 1024 1024"
        width="12px"
        height="11px"
        fill={
          direction === null
            ? '#00000091'
            : direction === 'up'
              ? '#fb7b34'
              : '#00000091'
        }
      >
        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
      </svg>
      <svg
        viewBox="0 0 1024 1024"
        width="12px"
        height="11px"
        fill={
          direction === null
            ? '#00000091'
            : direction === 'down'
              ? '#fb7b34'
              : '#00000091'
        }
      >
        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
      </svg>
    </div>
  );
};

const ClientSummary = ({ userId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation('');
  const {
    portfolioList: { list },
  } = useSelector((state) => state.portfolioReducer);
  const { isLoading } = useSelector((state) => state.portfolioReducer);
  const cashes = useSelector((state) => state.instrumentsReducer?.cashes?.list);
  const { currencyList } = useSelector((state) => state.instrumentsReducer);
  const totalInit = {
    cash: 0,
    financial: 0,
    cashWaight: 0,
    finWaight: 0
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [portfolioContent, setPortfolioContent] = useState([]);
  const [portfolioContentCash, setPortfolioContentCash] = useState([]);
  const [totalPortfolio, setTotalPortfolio] = useState(totalInit);
  const [pieData, setPieData] = useState(null);
  const [asseData, setAsseData] = useState(null);
  const [assetView, setAssetView] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [sortName, setSortName] = useState(null);
  const [sortClass, setSortClass] = useState(null);
  const [sortAllocation, setSortAllocation] = useState(null);

  useEffect(() => {
    if (selectedPortfolio && cashes) {
      const data = getPieData(selectedPortfolio, cashes);
      setPieData(data);
    }
    if (selectedPortfolio && currencyList) {
      const assetData = getAsseData(selectedPortfolio, currencyList);
      setAsseData(assetData);
    }
  }, [selectedPortfolio, cashes, currencyList]);

  useEffect(() => {
    if (list) {
      const selected = list?.filter((i) => selectedRowKeys.includes(i.ID));
      setSelectedPortfolio(selected);
    }
    const portfolioContent = [];
    const portfolioContentCash = [];

    let total_value = 0;
    let totalValueFeeAcc = 0;
    let financial_total_value = 0;
    let cash_total_value = 0;

    const getCashData = (id) => {
      const ccc = cashes?.find((i) => i.ID === id);
      return ccc;
    };
    const getCashRate = (id) => {
      return currencyList?.find((i) => i.id === id)?.rate;
    };
    if (selectedRowKeys?.length) {
      selectedRowKeys.forEach((key) => {
        const portfolio = list?.find((i) => i.ID === key);

        total_value = total_value + portfolio?.total_value ?? 0;
        financial_total_value = financial_total_value + (portfolio?.financial_total_value ?? 0);
        cash_total_value = cash_total_value + (portfolio?.cash_total_value ?? 0);
        totalValueFeeAcc = (portfolio?.total_fee ?? 0) * (portfolio?.total_value ?? 0);

        const listed =
          portfolio?.portfolioContent?.map((i) => {
            return { ...i, portfolio_code: portfolio.portfolio_code };
          }) || [];
        const unListed =
          portfolio?.unlistedContent?.map((i) => {
            return {
              ...i,
              ISIN: '- - - -',
              portfolio_code: portfolio.portfolio_code,
            };
          }) || [];
        const cashes =
          portfolio?.portfolioCashes?.map((i) => {
            return {
              ...i,
              instrumentName: getCashData(i.instrument_id)?.name,
              ISIN: '- - - -',
              unitsNumber: i.amount,
              latest_value_sek: getCashRate(i.currency_id),
              latest_value_time: Date.now(),
              value: i.amount * getCashRate(i.currency_id),
              fee: 0,
              financialAsset: getCashData(i.instrument_id)?.financialAsset,
              financialAssetCategory: getCashData(i.instrument_id)
                ?.financialAsset?.financialAssetCategory,
              portfolio_code: portfolio.portfolio_code,
            };
          }) || [];
        portfolioContent.push(...listed, ...unListed);
        portfolioContentCash.push(...cashes);
      });

      const waight = total_value ? cash_total_value * 100 / total_value : 0;
      setPortfolioContent(portfolioContent);
      setPortfolioContentCash(portfolioContentCash);
      setTotalPortfolio({
        totalValue: total_value,
        totalValueFee: totalValueFeeAcc / total_value,
        cash: cash_total_value,
        financial: financial_total_value,
        cashWaight: waight,
        financialWaight: 100 - waight
      });
    } else {
      setPortfolioContent([]);
      setPortfolioContentCash([]);
      setTotalPortfolio(totalInit);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    if (list?.length) {
      const defaultSelect = list.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (dateA < dateB) {
          return 1;
        } else if (dateA > dateB) {
          return -1;
        } else {
          return 0;
        }
      })[0];
      setSelectedRowKeys([defaultSelect?.ID]);
    }
  }, [list]);

  useEffect(() => {
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' }));
    dispatch(getCurrency());

    return () => {
      dispatch(clearCandidates());
    };
  }, []);

  useEffect(() => {
    dispatch(getAllPortfolio(userId));
  }, [userId]);

  const getExternalIDs = () => {
    const extId = selectedRowKeys
      .map((key) => list?.find((i) => i.ID === key)?.externalID)
      ?.join(', ');
    return `${extId}`;
  };

  const handleOptimise = () => {
    const [portfolioId] = selectedRowKeys;
    history.push(`/clients/${userId}/optimization/${portfolioId}/opt-settings`);
  };
  const disableOptimise = useMemo(() => {
    if (selectedRowKeys?.length !== 1) return true;

    if (selectedRowKeys?.length === 1) {
      const isDepr = list?.find(i => i.ID === selectedRowKeys[0])?.status === 'ACTIVE_DEPRECATED';
      return isDepr;
    }
  }, [selectedRowKeys]);

  const handleEdit = () => {
    const [portfolioId] = selectedRowKeys;
    history.push(`${pathname}/${portfolioId}/portfolio-information`);
  };
  const getPortfolioForChart = useMemo(() => {
    if (selectedRowKeys?.length && list?.length) {
      const selected = list?.filter((i) => selectedRowKeys.includes(i.ID));
      return selected.reduce((acc, current) => current.total_value + acc, 0);
    } else return 0;
  }, [selectedRowKeys, list]);

  const onExport = () => {
    dispatch(
      exportSelectedPortfolio({
        portfolio_ids: selectedRowKeys,
        format: { key: 'xlsx' },
      })
    );
    setOpenExport(false);
  };

  const handleToogleView = () => {
    setAssetView(!assetView);
  };

  const handleCreateModal = () => {
    history.push(`/clients/${userId}/new-portfolio`);
  };

  const handleSort = (coll) => {
    if (coll === 'name') {
      setSortClass(null);
      setSortAllocation(null);
      if (sortName !== null) {
        if (sortName) {
          setSortName(false);
        } else {
          setSortName(null);
        }
      } else {
        setSortName(true);
      }
    } else if (coll === 'class') {
      setSortName(null);
      setSortAllocation(null);
      if (sortClass !== null) {
        if (!sortClass) {
          setSortClass(true);
        } else {
          setSortClass(null);
        }
      } else {
        setSortClass(false);
      }
    } else if (coll === 'allocation') {
      setSortName(null);
      setSortClass(null);
      if (sortAllocation !== null) {
        if (!sortAllocation) {
          setSortAllocation(true);
        } else {
          setSortAllocation(null);
        }
      } else {
        setSortAllocation(false);
      }
    }
  };
  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) {
      return -1 * direction;
    }
    if (a?.toLowerCase() > b?.toLowerCase()) {
      return 1 * direction;
    }
    return 0;
  };

  useEffect(() => {
    if (asseData) {
      if (sortClass || sortAllocation) {
        setAsseData({
          ...asseData,
          assetClass: asseData.assetClass.sort((a, b) => a.y - b.y),
        });
      } else if (sortName || sortName !== null) {
        const direction = sortName ? 1 : -1;
        setAsseData({
          ...asseData,
          assetClass: getAsseData(
            selectedPortfolio,
            currencyList
          )?.assetClass?.sort((a, b) => textSort(a.name, b.name, direction)),
        });
      } else {
        setAsseData({
          ...asseData,
          assetClass: asseData.assetClass.sort((a, b) => b.y - a.y),
        });
      }
    }
  }, [sortClass, sortAllocation, sortName]);

  const handleExportSelected = () => {
    setOpenExport(true);
  };

  const getPortfolioNames = () => {
    const selectedPortfolio = list
      ?.filter((i) => selectedRowKeys?.includes(i.ID))
      ?.map((i) => i.name);
    return selectedPortfolio?.join(', ');
  };

  return (
    <div className="portfolio-summary-container">
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t('OVERVIEW')}
            </Button>
          </Col>
          {!!list?.length && (
            <Col className="tabs-btn-wrapper" span={6}>
              <Button
                type="default"
                className="edit-btn"
                onClick={handleEdit}
                disabled={selectedRowKeys?.length !== 1}
              >
                {t('EDIT')}
              </Button>
              <Button
                type="link"
                className="optimise-btn"
                onClick={handleOptimise}
                disabled={disableOptimise}
              >
                {t('OPTIMISE')}
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      {list && !list?.length
        ? (
        <>
          <div className="portfolio-title-wrapper">
            <h2 className="portfolio-container-title">{t('PORTFOLIO')}</h2>
          </div>
          <div className="noportfolio-wrapper">
            <Container className="noportfolio-container">
              <h2 className="noportfolio-title">{t('NO_PORTFOLIO')}</h2>
              <p className="noportfolio-text">{t('NOPORTFOLIO_TEXT')}</p>
              <Button
                className="optimise-btn noportfolio-create-btn"
                onClick={handleCreateModal}
              >
                {t('CREATE_PORTFOLIO')}
              </Button>
            </Container>
          </div>
        </>
          )
        : (
        <>
          <div className="portfolio-title-wrapper">
            {!isLoading
              ? (
              <>
                <h2 className="portfolio-container-title">{t('PORTFOLIOS')}</h2>
                <Button
                  type="text"
                  className="portfolio-add-btn"
                  onClick={handleCreateModal}
                  icon={<Pluse />}
                  iconPosition={'end'}
                >
                  {t('ADD_PORTFOLIO')}
                </Button>
              </>
                )
              : (
              <Skeleton containerClassName="skeleton-container-flex" />
                )}
          </div>
          <PortfolioList
            list={list}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            isLoading={isLoading}
          />
          <div style={{ padding: '0 24px', marginTop: '24px' }}>
            <Container widthP={100} mb="24px" padding="24px">
              <Row justify="space-between" align="middle">
                <Col style={{display: 'flex', alignItems: 'center', gap: '24px'}}>
                  <Title className="standing-title" level={3}>
                    {`${twoDecimalWithSpaces(getPortfolioForChart)} SEK`}
                  </Title>
                  {portfolioContent?.length
                    ? (
                    <Typography.Text
                      style={{
                        fontSize: 14,
                        marginleft: 33,
                        fontWeight: 400,
                      }}
                    >
                      {`(external Quant ID: ${getExternalIDs()})`}
                    </Typography.Text>
                      )
                    : null
                    }
                </Col>
                <Col>
                  <Button
                    type="text"
                    icon={<Export />}
                    iconPosition="end"
                    className="export-btn"
                    onClick={handleExportSelected}
                  >
                    {t('EXPORT_PORT')}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="portfolio-title-wrapper standing">
            {!isLoading
              ? (
              <h2 className="portfolio-container-title standing">
                {t('ALLOCATION')}
              </h2>
                )
              : (
              <SceletANTD.Input active />
                )}
          </div>
          <div className="standing-container">
            {!isLoading
              ? (
              <Container className="chart-pie-wrapper" padding="0">
                <h4 className="chart-pie-title">{t('DISTRIBUTION')}</h4>
                <div className="pie-charp-allocation-wrap">
                  <PieDonut data={pieData} style={{ margin: '0 auto' }} />
                </div>
                <div className="category-data-wrapper">
                  {pieData?.category?.map((item) => {
                    return (
                      <Row key={item.name} className="category-row">
                        <div className="category-cell-first">
                          <div
                            style={{
                              width: '14px',
                              height: '14px',
                              backgroundColor: item.color,
                            }}
                          />
                          <span>{item.name}</span>
                        </div>
                        <div className="category-cell-second">
                          {formatPercentage2(
                            (item.y * 100) / pieData.dataTotal, 1
                          )}
                          %
                        </div>
                        <div className="category-cell-third">
                          {twoDecimalWithSpaces(item.y)} Sek
                        </div>
                      </Row>
                    );
                  })}
                </div>
              </Container>
                )
              : (
              <Skeleton
                containerClassName="skeleton-container-flex"
                style={{ width: '99%' }}
                height={432}
              />
                )}
            {!isLoading
              ? (
              <Container
                className="asset-class-wrapper"
                mb="14px"
                padding="0 10px 10px"
              >
                <h4 className="chart-pie-title">
                  {t('ASSET_CLASS_ALLOCATION')}
                </h4>
                <Row wrap={false} className="asset-class-title">
                  <Col span={14} className="asset-class-title-name">
                    {t('NAME')}
                    <div onClick={() => handleSort('name')}>
                      <SortArrow
                        direction={
                          sortName ? 'up' : sortName !== null ? 'down' : ''
                        }
                      />
                    </div>
                  </Col>
                  <Col span={5} className="asset-class-title-value">
                    {t('VALUE')}
                    <div onClick={() => handleSort('class')}>
                      <SortArrow
                        direction={
                          sortClass ? 'up' : sortClass !== null ? 'down' : ''
                        }
                      />
                    </div>
                  </Col>
                  <Col span={5} className="asset-class-title-allocation">
                    {t('ALLOCATION')}
                    <div onClick={() => handleSort('allocation')}>
                      <SortArrow
                        direction={
                          sortAllocation
                            ? 'up'
                            : sortAllocation !== null
                              ? 'down'
                              : ''
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {asseData?.assetClass &&
                  asseData?.assetClass
                    .slice(0, assetView ? asseData?.assetClass?.length : 6)
                    .map((item, index) => {
                      const persent =
                        Math.round(
                          ((item.y * 100) / asseData.dataTotal) * 100
                        ) / 100;
                      return (
                        <Row
                          key={item.name}
                          wrap={false}
                          className={`asset-class-data ${
                            index % 2 === 0 ? 'is-odd' : ''
                          }`}
                        >
                          <Col span={14} className="asset-class-data-name">
                            {item?.name}
                            <Progress
                              percent={persent}
                              showInfo={false}
                              trailColor={index % 2 === 0 ? '#fff' : '#F9FAFB'}
                              strokeColor="#474747"
                            />
                          </Col>
                          <Col span={5} className="asset-class-data-value">
                            {twoDecimalWithSpaces(item?.y)} SEK
                          </Col>
                          <Col span={5} className="asset-class-data-allocation">
                            <div className="asset-class-data-allocation-cell">
                              {formatPercentage2(item?.weight, 1)} %
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                {asseData?.assetClass && asseData?.assetClass.length > 6 && (
                  <Row
                    wrap={false}
                    justify={'center'}
                    align={'middle'}
                    className="asset-more-btn"
                  >
                    <Button
                      type="text"
                      icon={assetView ? <Collapse /> : <More />}
                      iconPosition="end"
                      onClick={handleToogleView}
                    >
                      {t('VIEW_MORE')}
                    </Button>
                  </Row>
                )}
              </Container>
                )
              : (
              <Skeleton
                containerClassName="skeleton-container-flex"
                style={{ width: '99%' }}
                height={432}
              />
                )}
          </div>
          <div className="standing-container">
            <Container
              className="current-portfolio-Holdings"
              padding="0 10px 10px"
            >
              <Row
                style={{ padding: '31px 30px 27px 30px' }}
                align="middle"
                justify={'space-between'}
              >
                <Col>
                  <Typography.Text className="holdings-title">
                    {t('CASH')}
                  </Typography.Text>
                </Col>
                <Col className="holdings-value-wrapp">
                  <span className="holdings-text">{t('TOTAL')}:</span>
                  <span className="holdings-value">
                    {twoDecimalWithSpaces(totalPortfolio.cash)}{' '}
                    kr
                  </span>
                    <span className="holdings-value-fee" style={{ cursor: 'text' }}>
                      {twoDecimalWithSpaces(totalPortfolio?.cashWaight)}%
                    </span>
                </Col>
              </Row>
              <Cash
                currentPortfolio={portfolioContentCash}
                currencyList={currencyList}
              />
            </Container>
          </div>
          <div className="standing-container">
            <Container
              className="current-portfolio-Holdings"
              padding="0 10px 10px"
            >
              <Row
                style={{ padding: '31px 30px 27px 30px' }}
                align="middle"
                justify={'space-between'}
              >
                <Col>
                  <Typography.Text className="holdings-title">
                    {t('HOLDINGS')}
                  </Typography.Text>
                </Col>
                <Col className="holdings-value-wrapp">
                  <span className="holdings-text">{t('TOTAL')}:</span>
                  <span className="holdings-value">
                    {twoDecimalWithSpaces(totalPortfolio?.financial)}{' '}
                    kr
                  </span>
                  <span className="holdings-value-fee" style={{ cursor: 'text' }}>
                      {twoDecimalWithSpaces(100 - totalPortfolio?.cashWaight)}%
                    </span>
                  <Tooltip
                    placement="topLeft"
                    overlayStyle={{ width: '100%', borderRadius: '14px' }}
                    title={
                      <span style={{ width: '100%', color: '#000000' }}>
                        {t('HOLDINGS_FEE_TOOLTOP')}
                      </span>
                    }
                    color="white"
                  >
                    <span className="holdings-value-fee">
                      {twoDecimalWithSpaces(totalPortfolio?.totalValueFee)}%
                    </span>
                  </Tooltip>
                </Col>
              </Row>
              <Holdings
                currentPortfolio={portfolioContent}
                currencyList={currencyList}
              />
            </Container>
          </div>

        </>
          )}
      <Modal
        width={650}
        className="modal-content-paper"
        open={openExport}
        onCancel={() => setOpenExport(false)}
        footer={null}
        zIndex={1600}
      >
        <Row justify="center">
          <Col className="modal-title">
            {selectedRowKeys?.length > 1
              ? t('EXPORT_PORTFOLIOS')
              : t('EXPORT_PORTFOLIO')}
          </Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col>
            <p className="modal-advisor-text">
              {selectedRowKeys?.length > 1
                ? t('EXPORT_PORTFOLIOS_PROMT')
                : t('EXPORT_PORTFOLIO_PROMT')}
            </p>
            <p className="modal-advisor-name" style={{ fontWeight: '600' }}>
              {getPortfolioNames()}
            </p>
          </Col>
        </Row>
        <Row className="modal-advisor-btn-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={() => setOpenExport(false)}
          >
            {t('CANCEL')}
          </Button>
          <Button className="modal-action-btn save-btn" onClick={onExport}>
            {t('EXPORT')}
          </Button>
        </Row>
      </Modal>
      <MainFooter />
    </div>
  );
};

export default ClientSummary;

ClientSummary.propTypes = {
  userId: PropTypes.string,
};

SortArrow.propTypes = {
  direction: PropTypes.string,
};
