import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import ModelList from './ModelList';
import ModelItems from './ModelItems';
import ModelItemView from './ModelItemView';

const ModelRouting = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/list`}>
        <ModelList />
      </Route>
      <Route path={`${url}/item/:itemKey`}>
        <ModelItems />
      </Route>
      <Route path={`${url}/item-view/:itemKey`}>
        <ModelItemView />
      </Route>
      <Route path="*">
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
};

export default ModelRouting;
